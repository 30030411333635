import styled from 'styled-components';
import { color_dict } from '../../utils/ColorDict';

export const Card = styled.div`
    min-height: 1;
    min-width: 1;
    background-color: ${color_dict.backgroundColor};
    
    overflow: hidden;
    margin-top: 10px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
`;

export const UserInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const Avatar = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
`;

export const UserMeta = styled.div`
    display: flex;
    flex-direction: column;
`;

export const UserName = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: white;
`;

export const PostDate = styled.span`
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
`;

export const MenuButton = styled.button`
    background: none;
    border: none;
    color: white;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    transition: opacity 0.2s ease;

    &:hover {
        opacity: 1;
    }
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2px;
    aspect-ratio: 1;
    width: 100%;
`;

export const GridImage = styled.img`
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
`;

export const ImageCarousel = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 1;
    display: flex;
`;

export const CarouselImage = styled.img`
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    flex-shrink: 0;
`;

export const CarouselDots = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 4px 12px;
`;

export const Dot = styled.div`
    width: ${props => props.$active ? '6px' : '4px'};
    height: ${props => props.$active ? '6px' : '4px'};
    border-radius: 50%;
    background-color: ${props => props.$active ? 'white' : 'rgba(255, 255, 255, 0.3)'};
    cursor: pointer;
    transition: background-color 0.2s ease width 0.2s ease height 0.2s ease;
`; 