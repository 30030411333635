const  LoadingThingie = ({style}) => {
    return (
        <div style={{
            width: '50px',
            height: '50px',
            border: '5px solid rgba(255, 215, 0, 0.3)',
            borderTop: '5px solid #FFD700',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
            ...style,

            
          }}>
            <style>
              {`
                @keyframes spin {
                  0% { rotate: 0deg; }
                  100% { rotate: 360deg; }
                }
              `}
            </style>
          </div>
    )
}

export default LoadingThingie;