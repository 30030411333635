import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IoChevronBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { FaCoins } from 'react-icons/fa';
import { getCreditHistory } from '../services/api';
import WebappContainer from '../components/common/WebappContainer';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #1a1a1a;
    color: white;
`;

const TopBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #252525;
    padding: 8px 16px;
    height: 60px;
`;

const BackButton = styled.button`
    background: none;
    border: none;
    color: white;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Title = styled.h1`
    margin: 0;
    margin-left: 8px;
    font-size: 20px;
    font-weight: 600;
`;

const Content = styled.div`
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
`;

const TransactionList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Transaction = styled.div`
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TransactionInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Description = styled.div`
    font-size: 16px;
    color: #fff;
`;

const DateTime = styled.div`
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
`;

const Amount = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.amount > 0 ? '#4CAF50' : '#FF5252'};
`;

const CreditIcon = styled(FaCoins)`
    color: #FFD700;
    font-size: 14px;
`;

const CreditHistoryScreen = () => {
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const data = await getCreditHistory();
                setHistory(data.transactions);
            } catch (error) {
                console.error('Failed to load credit history:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHistory();
    }, []);

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    };

    return (
        <WebappContainer>
            <TopBar>
                <BackButton onClick={() => navigate('/user-profile')}>
                    <IoChevronBack size={24} />
                </BackButton>
                <Title>Credit History</Title>
            </TopBar>

            <Content>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <TransactionList>
                        {history.map(transaction => (
                            <Transaction key={transaction._id}>
                                <TransactionInfo>
                                    <Description>{transaction.description}</Description>
                                    <DateTime>{formatDate(transaction.created_at)}</DateTime>
                                </TransactionInfo>
                                <Amount amount={transaction.amount}>
                                    {transaction.amount > 0 ? '+' : ''}{transaction.amount}
                                    <CreditIcon />
                                </Amount>
                            </Transaction>
                        ))}
                    </TransactionList>
                )}
            </Content>
        </WebappContainer>
    );
};

export default CreditHistoryScreen; 