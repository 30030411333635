import React, { useEffect, useRef } from 'react';
import BundleCard from './BundleCard';

const Feed = ({ bundles, setReloadBundles }) => {
    const feedRef = useRef(null);
    
    useEffect(() => {
        if (feedRef.current) {
            // Using smooth scroll behavior for animation
            feedRef.current.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [bundles]); // This effect runs whenever bundles changes

    return (
        <div 
            ref={feedRef}
            style={{
                width:'100%',
                maxWidth:'600px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'stretch',
                // overflow:'scroll',
            }}
        >
            {bundles.map((bundle) => (
                <BundleCard
                    key={bundle.id}
                    bundle={bundle}
                    setReloadBundles={setReloadBundles}
                />
            ))}
        </div>
    );
};

export default Feed;