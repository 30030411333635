import styled from "styled-components";
import { color_dict } from "../utils/ColorDict";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${color_dict.backgroundColor};
  color: white;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  padding: 20px;
  gap: 4px;
  background-color: #252525;
  z-index: 2;
`;

export const ProgressStep = styled.div`
  flex: 1;
  height: 3px;
  background-color: ${({ $isActive, $isCompleted }) =>
    $isCompleted
      ? color_dict.logo_pink
      : $isActive
        ? color_dict.logo_pink
        : "rgba(255, 255, 255, 0.2)"};
  transition: background-color 0.3s ease;
`;

export const StepContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
  min-height: 0;
  z-index: 2;

  h1 {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }

  > div {
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 400px;
    min-height: 0;
  }
`;

export const GenderImage = styled.img`
width: 40vw;
height: 40vw;
object-fit: cover;
border-radius: 12px;
`;

export const GenderOption = styled.button`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: 0;
  padding-top: 15px;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background-color: ${({ selected }) => (selected ? "#FFD700" : "#252525")};
  color: ${({ selected }) => (selected ? "#1a1a1a" : "whitesmoke")};
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;

  span {
    font-weight: 500;
    font-size: 18px;
    font-family: Plus Jakarta Sans;
  }

  &:hover {
    transform: translateY(-2px);
    background-color: ${({ selected }) => (selected ? "#FFD700" : "#303030")};
  }
`;

export const NextButton = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  background-color: #FFD700;
  color: ${color_dict.backgroundColor};
  border: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;

  &:hover {
    transform: translateX(2px);
    background-color: #FFEB3B;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  
  img {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 24px;
  }
`;

export const StarContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 24px 0;
`;

export const Star = styled.img`
  width: 32px;
  height: 32px;
`;

export const MotivationalText = styled.h2`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 32px;
`;

export const ReferralInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  gap: 8px;
  min-height: 0;
`;

export const ReferralInput = styled.input`
  width: 70%;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #252525;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 16px;
  text-align: center;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    border-color: #FFD700;
  }
`;

export const SecondaryText = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: 'Arial', sans-serif;
`;

export const CameraContainer = styled.div`
  width: 100%;
  max-width: 400px;
  aspect-ratio: 3/4;
  background-color: #252525;
  border-radius: 12px;
  overflow: hidden;
  margin: 20px 0;
  position: relative;
  min-height: 0;
`;

export const CameraWindow = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);
`;

export const CaptureButton = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  background-color: #FFD700;
  color: #1a1a1a;
  border: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.05);
    background-color: #FFEB3B;
  }
`;

export const UploadButton = styled(CaptureButton)`
  background-color: #252525;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);

  &:hover {
    background-color: #303030;
  }
`;

export const SubTitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 16px;
  margin: 0;
  margin-top: 8px;
`;

export const GoogleSignInButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #444;
  cursor: pointer;
  transition: background-color 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #eee;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 5px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  gap: 15px;
  z-index: 2;
`;