import styled from 'styled-components';
import { color_dict } from '../utils/ColorDict';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px;
    gap: 24px;
`;

export const Title = styled.h1`
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    color: white;
`;

export const Description = styled.p`
    font-size: 16px;
    color: ${color_dict.text_gray};
    margin: 0;
`;

export const CameraContainer = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    background-color: ${color_dict.background_dark};
`;

export const CameraWindow = styled.video`
    width: 100%;
    height: 100%;
    transform: scaleX(-1);
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const HeadGuideOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 38%;
  aspect-ratio: 3/4;
  border: 2px dashed rgba(255, 255, 255, 0.7);
  border-radius: 50% 50% 50% 50% / 55% 55% 45% 45%;  // creates a more symmetrical head shape
  pointer-events: none;  // allows clicks to pass through
  z-index: 1000;
`;

export const ImagePreview = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 0;
    min-height: fit-content;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    z-index: 10;
    padding: 8px;
    
    &:hover {
        opacity: 0.8;
    }
`;

export const ErrorMessage = styled.p`
    color: ${color_dict.error_red};
    text-align: center;
    margin: 0;
`; 