import React, { useState, useRef, useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {
  Container,
  ProgressBarContainer,
  ProgressStep,
  StepContainer,
  GenderOption,
  GenderImage,
  NextButton,
  LogoContainer,
  StarContainer,
  Star,
  MotivationalText,
  ReferralInputContainer,
  ReferralInput,
  SecondaryText,
  CameraContainer,
  CameraWindow,
  CaptureButton,
  UploadButton,
  SubTitle,
  ButtonContainer,
} from "./OnboardingScreen.styles";
import { PiArrowArcRightLight } from "react-icons/pi";

import { useNavigate } from "react-router-dom";

import "../google.css";
import { authenticateWithGoogle, handleConnectivityError } from '../services/api';
import { color_dict } from "../utils/ColorDict";
import { Button } from "../components/common/Button.styles";
import LooxerButton from "../components/common/Button";
import WebappContainer from "../components/common/WebappContainer";

const Welcome = ({ }) => {
  return (
    <div style={{
      // backgroundColor:'red',

      flex: '1 0 0%',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',

      gap: '15px',
      boxSizing: 'border-box',
    }}>

      <video autoPlay
        loop
        muted
        playsInline

        style={{
          width: '150px',
        }}
      >
        <source src="/images/alpha_logo.webm" />
      </video>

      <div style={{
        fontSize: '30px',
        textAlign: 'center',
        fontWeight: '200'
      }}>
        What would you look like as a <span style={{ fontWeight: '900' }}>mogger</span>?
      </div>
      <div style={{
        // backgroundColor:'red',
        width: '100%',
        flex: '1 0 0%',
        position: 'relative',
      }}>
        <img src="https://looxer-media-dev.s3.amazonaws.com/user_media/67575626e838f5feabd1942e/83r20npNLevwQzcxx5dxaqLmpPnxljNH/67575626e838f5feabd1942e_1734036701.006354.png" style={{
          position: 'absolute',

          width: '120px',

          transform: 'translateX(-50%) translateY(-50%) rotate(-10deg)',

          top: '30%',
          left: '20%',

        }} />

        <img src="https://looxer-media-dev.s3.amazonaws.com/user_media/67575626e838f5feabd1942e/3bUho3baiMkDRvPjLY5BToQQp6WkweBc/67575626e838f5feabd1942e_1734036707.374044.png" style={{
          position: 'absolute',

          width: '120px',

          transform: 'translateX(-50%) translateY(-50%) rotate(10deg)',

          top: '30%',
          left: '80%',

        }} />

        <PiArrowArcRightLight size="35px" style={{
          position: 'absolute',
          top: '20%',
          left: '45%',
          transform: 'translateX(-50%) translateY(-50%) rotate(20deg)',
        }} />

        <video src="https://looxer-media-dev.s3.amazonaws.com/user_media/67575626e838f5feabd1942e/drVCsvtdpSdGnuE4R6KZGkXCNlUOEFF1/67575626e838f5feabd1942e_1734048581.576298.mp4" autoPlay
          loop
          muted
          playsInline
          style={{
            position: 'absolute',

            width: '140px',

            transform: 'translateX(-50%) translateY(-50%)',

            top: '70%',
            left: '50%',

          }}
        />
      </div>
    </div>
  )
}

const GenderCard = ({ selected, title, image, onClick }) => {
  return (
    <div style={{
      backgroundColor: selected ? color_dict.logo_purple : color_dict.lighterBackgroundColor,
      width: '150px',
      height: '200px',

      borderRadius: '10px',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',

      transform: `scale(${selected ? 1.1 : 1})`,
      transition: '0.2s all',

      fontWeight: '700',
      overflow: 'hidden',

      boxSizing: 'border-box',
    }}
      onClick={onClick}
    >
      <div style={{
        padding: '10px',

      }}>{title}</div>

      <div style={{
        // backgroundColor: 'red',
        width: '100%',
        flex: '1 0 0%',

        position: 'relative'
      }}>

        <img src={image} style={{
          opacity: selected ? 1 : 0.8,
          width: '110%',

          position: 'absolute',
          left: '50%',
          bottom: '-10px',

          transform: 'translateX(-50%)',

          zIndex: 1,

          WebkitMaskImage: 'linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 95%)',
          maskImage: 'linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 95%)',

          WebkitMaskSize: '100% 100%',
          maskSize: '100% 100%',

        }} />
      </div>
    </div>
  )
}

const GenderChoice = ({ setSelectedGender, selectedGender }) => {

  return (
    <div style={{
      // backgroundColor:'red',

      flex: '1 0 0%',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    }}>

      <div style={{
        // backgroundColor:'green',
        fontSize: '30px',
      }}>
        Select Gender
      </div>

      <div style={{
        // backgroundColor:'red',
        flex: '1 0 0%',


        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
      }}>
        <GenderCard title="Chad" image="/images/chad1.png" selected={selectedGender == 'male'} onClick={() => setSelectedGender("male")} />
        <GenderCard title="Stacy" image="/images/chadgirl1.png" selected={selectedGender == 'female'} onClick={() => setSelectedGender("female")} />
      </div>

    </div>
  )
}


const TOTAL_STEPS = 3;
const SignInWithGoogle = () => {
  const handleSuccess = async (credentialResponse) => {
    try {
      const data = await authenticateWithGoogle(credentialResponse.credential);
      console.log('Logged in user:', data.user);
      window.location.href = '/';
    } catch (error) {
      if (error.message.includes("Network Error")) {
        handleConnectivityError();
      }
    }
  };

  return (
    <div style={{ flex: "0 1", alignItems: 'center', justifyContent: 'center' }}>
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={() => {
          console.log('Login Failed');
        }}
        useOneTap
      />
    </div>
  );
};

const OnboardingScreen = () => {

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedGender, setSelectedGender] = useState(null);
  const [referralCode, setReferralCode] = useState("");
  const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(false);

  const handleNext = () => {
    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 1 && selectedGender) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 2) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 3) {
      console.log('Completing sign in...');
    }
  };


  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <Welcome />
        )
      case 1:
        return (
          <GenderChoice
            setSelectedGender={setSelectedGender}
            selectedGender={selectedGender}
          />
        );
      // case 2:
      //   return (
      //     <>
      //       <div style={{ flex: 1 }}>&nbsp;</div>
      //       <MotivationalText style={{ marginBottom: "8px" }}>Over 15,000 people already joined Looxer</MotivationalText>
      //       <SubTitle style={{ marginBottom: "30px" }}>Looxer will generate photos and videos of you as a model, and help you track your progress there.</SubTitle>
      //       <LogoContainer>
      //         {/* Replace with your actual logo */}
      //         <img src="/images/looxer.png" alt="Logo" />
      //       </LogoContainer>
      //       <StarContainer>
      //         {[...Array(5)].map((_, index) => (
      //           <Star key={index} src="/images/star.png" alt="Star" />
      //         ))}
      //       </StarContainer>
      //       <div style={{ flex: 1 }}>&nbsp;</div>
      //     </>
      //   );
      case 2:
        return (
          <>
            <div style={{ flex: 0.7 }}>&nbsp;</div>
            <h2>{alreadyHaveAccount ? "SIGN IN WITH GOOGLE" : "CREATE YOUR ACCOUNT"}</h2>
            {alreadyHaveAccount || (
              <SubTitle>
                Sign in with Google to find out how you can get the best out of your life
              </SubTitle>
            )}
            <br /><br />
            <SignInWithGoogle />
            <div style={{ flex: 1 }}>&nbsp;</div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <GoogleOAuthProvider clientId="31797981954-cj390ehr2fidhbhlf26dbqrucpmlu9tg.apps.googleusercontent.com">
      <WebappContainer>
        <div style={{
          background: `linear-gradient(to bottom, rgb(0, 0, 0, 1) 0%,
rgb(0, 0, 0, 1) 5%,
rgb(0, 0, 0, 0) 30%,
rgb(0, 0, 0, 0) 70%,
rgb(0, 0, 0, 1) 99%,
rgb(0, 0, 0, 1) 100%
          )`,
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex:0,
        }}>
        </div>

        <ProgressBarContainer>
          {Array.from({ length: TOTAL_STEPS }).map((_, index) => (
            <ProgressStep
              key={index}
              $isActive={index <= currentStep}
              $isCompleted={index + 1 < currentStep}
            />
          ))}

        </ProgressBarContainer>

        <StepContainer>
          {
            renderStep()
          }
        </StepContainer>


        {currentStep !== 3 && (
          <>
            <ButtonContainer>

              <LooxerButton
                onClick={handleNext}
                text="Continue"
                active={currentStep !== 1 || selectedGender}
              />

              <div
                onClick={() => {
                  setCurrentStep(2);
                  setAlreadyHaveAccount(true);
                }}
                style={{
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '14px'
                }}
              >
                I already have an account
              </div>
            </ButtonContainer>
          </>
        )}
        <div style={{
          // backgroundColor:'red',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',

          gap: '20px',
          padding: '10px',

          fontSize: '12px',
          color: 'gray',
          zIndex: 2,
        }}>
          <div onClick={() => { window.location.href = "/tos.txt"; }}>Terms of service</div>
          <div onClick={() => { window.location.href = "/privacy.txt"; }}>Privacy Policy</div>
          <div onClick={() => { window.location.href = "/refunds.txt"; }}>Refund Policy</div>
        </div>
      </WebappContainer>
    </GoogleOAuthProvider>
  );
};

export default OnboardingScreen; 