import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { selectDialog, closeDialog } from '../../features/dialog/dialogSlice';
import LooxerButton from './Button';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    gap: 20px;
`;

const DialogContainer = styled.div`
    background-color: #252525;
    padding: 32px;
    max-width: 400px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 20px;
`;

const Title = styled.h2`
    color: white;
    margin: 0 0 16px 0;
    font-size: 24px;
    font-weight: 600;
`;

const Message = styled.p`
    color: rgba(255, 255, 255, 0.8);
    margin: 0 0 32px 0;
    font-size: 16px;
    line-height: 1.5;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: center;
    color: white;
`;

const Dialog = () => {
    const dispatch = useDispatch();
    const { isOpen, title, message, onConfirm, onCancel, confirmText, cancelText } = useSelector(selectDialog);

    if (!isOpen) return null;

    const handleConfirm = () => {
        if (onConfirm) onConfirm();
        dispatch(closeDialog());
    };

    const handleCancel = () => {
        if (onCancel) onCancel();
        dispatch(closeDialog());
    };

    return (
        <Overlay>
            <DialogContainer>
                <Title>{title}</Title>
                <Message>{message}</Message>
                <ButtonContainer>
                    {cancelText && (
                        <LooxerButton
                            text={cancelText}
                            onClick={handleCancel}
                            active={true}
                            secondary={true}
                            style={{ flex: '0 1 auto' }}
                        />
                    )}
                    <LooxerButton
                        text={confirmText || 'OK'}
                        onClick={handleConfirm}
                        active={true}
                        style={{ flex: 1 }}
                    />
                </ButtonContainer>
            </DialogContainer>
        </Overlay>
    );
};

export default Dialog; 