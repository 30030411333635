import './App.css';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { useDispatch } from 'react-redux';
import { setCredentials, setLoading } from './features/auth/authSlice';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import OnboardingScreen from './screens/OnboardingScreen';
import ProtectedRoute from './components/ProtectedRoute';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from './features/auth/authSlice';
import { getCurrentUser } from './services/api';
import GetStartedScreen from './screens/GetStartedScreen';
import SubscriptionScreen from './screens/SubscriptionScreen';
import DashboardScreen from './screens/DashboardScreen';
import UploadNewImage from './screens/UploadNewImage';
import UserProfile from './screens/UserProfile';
import CreditHistoryScreen from './screens/CreditHistoryScreen';
import { AuthProvider } from './contexts/AuthContext';
import { color_dict } from './utils/ColorDict';
import ErrorDialog from './components/common/ErrorDialog';
import Dialog from './components/common/Dialog';
import PricingScreen from './screens/PricingScreen';
import InstagramRedirectScreen from './screens/InstagramRedirectScreen';

// TODO: For testing
// localStorage.access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjc1OGFjOWI3YjBiMzNmMmFhMjZhYTI0IiwiZW1haWwiOiJ5b3RhbS5zYWxtb25AZ21haWwuY29tIiwiZXhwIjoxNzMzOTUzMzIxfQ.aYoAe08SFyltrBtTMGVfEMmhejz5MEUfxOVFmw1ca_c';
// localStorage.user = '{"email":"yotam.salmon@gmail.com","id":"101261963980921103310","name":"Yotam Salmon","picture":"https://lh3.googleusercontent.com/a/ACg8ocKpKG5BBjflKhRaY-lhKMPdT9IXZMp_BSm_Tnx4LsLVvVMhjnYm=s96-c"}';

// Create a wrapper component to handle auth initialization
function LoadingScreen() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: color_dict.backgroundColor,
      color: 'white'
    }}>
      <div style={{
        width: '50px',
        height: '50px',
        border: `5px solid ${color_dict.pink_pale_background}`,
        borderTop: `5px solid ${color_dict.logo_pink}`,
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
      }}>
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      </div>
    </div>
  );
}

function AuthInit() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadUser, setReloadUser] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {

    if (!reloadUser) {
      return;
    }



    console.log('isAuthenticated', isAuthenticated);
    const initAuth = async () => {
      const userData = localStorage.getItem('user');
      const access_token = localStorage.getItem('access_token');

      if (userData && access_token) {
        try {
          const response = await getCurrentUser();
          if (response.success) {
            const { user } = response;
            dispatch(setCredentials(user));

            if (!user.has_first_image) {
              navigate('/get-started');
            }
            else if (!user.subscription_id) {
              navigate('/subscribe');
            }
          } else {
            localStorage.removeItem('user');
            localStorage.removeItem('access_token');
          }
        } catch (error) {
          if (error.message.includes("Failed to fetch")) {
            // ... do nothing ... the authenticated route will handle this
          } else {
            console.error('Failed to verify user:', error);
            localStorage.removeItem('user');
            localStorage.removeItem('access_token');
          }
        }
      }

      setReloadUser(false);
      setIsLoading(false);
      dispatch(setLoading(false));
    };
    initAuth();
  }, [reloadUser]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <InstagramRedirectScreen />
      <Routes>
        {/* Public Routes */}
        <Route
          path="/onboarding"
          element={
            isAuthenticated ? <Navigate to="/" replace /> : <OnboardingScreen />
          }
        />

        {/* Protected Routes */}
        <Route
          path="/get-started"
          element={
            <ProtectedRoute>
              <GetStartedScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <DashboardScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path="/subscribe"
          element={
            <SubscriptionScreen />
          }
        />

        <Route path="/upload" element={<UploadNewImage />} />
        <Route path="/user-profile" element={<ProtectedRoute><UserProfile setReloadUser={setReloadUser} reloadUser={reloadUser} /></ProtectedRoute>} />
        <Route path="/credit-history" element={<CreditHistoryScreen />} />
        <Route path="/pricing" element={<PricingScreen />} />

        <Route path="*" element={<div>404 - Page Not Found</div>} />
      </Routes>
    </>
  );
}

function App() {
  const [isInstagram, setIsInstagram] = useState(false);

  useEffect(() => {
    const isInInstagramOrTikTok = navigator.userAgent.includes('Instagram') ||
      navigator.userAgent.includes('TikTok') ||
      navigator.userAgent.includes('musical_ly') ||
      navigator.userAgent.includes('ByteLocale'); // For TikTok??
    setIsInstagram(isInInstagramOrTikTok);
  }, []);

  // If in Instagram, show only the redirect screen
  if (isInstagram) {
    return <InstagramRedirectScreen />;
  }

  return (
    <AuthProvider>
      <Provider store={store}>
        <BrowserRouter>
          <AuthInit />
          <Dialog />
          <ErrorDialog />
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  );
}

export default App;
