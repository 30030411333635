import styled from 'styled-components';

export const  Button = styled.button`
    padding: 16px 24px;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    transition: opacity 0.2s ease;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }

    &:active {
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`; 