import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { selectError, clearError } from '../../features/error/errorSlice';
import { color_dict } from '../../utils/ColorDict';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    gap: 20px;
`;

const Dialog = styled.div`
    background-color: #252525;
    padding: 32px;
    max-width: 400px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 20px;
`;

const Title = styled.h2`
    color: white;
    margin: 0 0 16px 0;
    font-size: 24px;
    font-weight: 600;
`;

const Message = styled.p`
    color: rgba(255, 255, 255, 0.8);
    margin: 0 0 32px 0;
    font-size: 16px;
    line-height: 1.5;
`;

const Button = styled.button`
    width: 100%;
    max-width: 300px;
    padding: 16px;
    border-radius: 8px;
    background-color: ${color_dict.logo_pink};
    color: #1a1a1a;
    border: none;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        transform: translateY(-2px);
        background-color: #FFEB3B;
    }

    &:active {
        transform: translateY(0);
    }
`;

const ErrorDialog = () => {
    const dispatch = useDispatch();
    const { isError, errorType, message } = useSelector(selectError);

    if (!isError) return null;

    const handleRefresh = () => {
        dispatch(clearError());
        window.location.reload();
    };

    return (
        <Overlay>
            <Dialog>
                <Title>
                    {errorType === 'connectivity'
                        ? 'Connection Error'
                        : 'Something went wrong'}
                </Title>
                <Message>{message}</Message>
                <Button onClick={handleRefresh}>
                    Refresh Page
                </Button>
            </Dialog>
        </Overlay>
    );
};

export default ErrorDialog; 