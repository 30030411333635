import React, { useState, useMemo, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import {
    Card,
    Header,
    UserInfo,
    Avatar,
    UserMeta,
    UserName,
    PostDate,
    MenuButton,
    ImageCarousel,
    CarouselDots,
    Dot,
    CarouselImage,
    GridContainer,
    GridImage
} from './BundleCard.styles';
import { FiMoreHorizontal, FiSend } from 'react-icons/fi';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import LoadingThingie from '../common/LoadingThingie';
import { getBundleInfo, createPslGodBundleVideo, createVideoForSpecificMedia } from '../../services/api';
import { transformBundle } from '../../utils/bundleTransforms';
import { useAsyncError, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../features/dialog/dialogSlice';
import { current } from '@reduxjs/toolkit';
import { RxBorderWidth } from 'react-icons/rx';

const carouselImageWithText = (img_url, text, emojis) => {
    return (
        <div style={{
            // backgroundColor: 'red',
            width: '100%',
            height: '100%',

            position: 'relative'
        }}>
            <img style={{
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
            }}
                src={img_url}
            />

            <div style={{
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                padding: '20px',
                boxSizing: 'border-box',

                fontFamily: 'Inter',

                fontSize: '40px',
                fontWeight: '900',

                // WebkitTextStroke: "3px black", // Stroke width and color

            }}>
                <div style={{
                    textShadow: `
      -2px -2px 0 black, 
      2px -2px 0 black, 
      -2px 2px 0 black, 
      2px 2px 0 black
    `,
                }}>
                    {text}
                </div>

                <div>
                    {emojis}
                </div>
            </div>


        </div>
    )
}


function getSecondsSince(dateString) {
    // Parse the input date string into a Date object
    const inputDate = new Date(dateString);

    // Get the current time as a Date object
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInMs = currentDate - inputDate;

    // Convert the difference to seconds
    const differenceInSeconds = Math.floor(differenceInMs / 1000);

    return differenceInSeconds;
}


const getCarouselElements = (images, bundleType, isProcessing, bundleData) => {
    if (!images) {
        return [];
    }

    if (isProcessing) {

        const og_image = images.filter((item) => (item.proc_type === 'og'))[0].url;

        return [
            <div style={{
                width: '100%',
                height: '100%',

                position: 'relative',

            }}>
                <img src={og_image} style={{
                    height: '100%',
                    position: 'absolute',

                    top: '50%',
                    left: '50%',

                    transform: 'translateX(-50%) translateY(-50%)'
                }} />
            </div>
        ];
    }

    try {
        if (bundleType === 1) {
            console.log('images: ', images);


            const cooked_image = images.filter((item) => (item.proc_type === 'src2src_cropped_ogbg'))[0].url;
            const htn_image = images.filter((item) => (item.proc_type === 'src2src_cropped_brows_cheeks_ogbg'))[0].url;
            const chad_image = images.filter((item) => (item.proc_type === 'src2src_cropped_brows_cheeks'))[0].url;
            const psl_god_image = images.filter((item) => (item.proc_type === 'src2trg_cropped_brows_cheeks'))[0].url;

            const elements = [
                carouselImageWithText(cooked_image, "Cooked", "💀🍳"),
                carouselImageWithText(htn_image, "HTN", "😎"),
                carouselImageWithText(chad_image, "Chad", "🤫🧏‍♂️"),
                carouselImageWithText(psl_god_image, "PSL GOD", "🤴🥶"),
            ];

            return elements;
        }
        else if (bundleType === 3) {
            console.log('bundletype 3 images: ', images);
            const psl_god_video = images.filter((item) => (item.proc_type === 'src2trg_cropped_brows_cheeks_vid'))[0].url;

            return [
                <div style={{
                    width: '100%',
                    height: '100%',

                    position: 'relative',

                }}>
                    <video src={psl_god_video} autoPlay loop muted playsInline style={{
                        height: '100%',
                        position: 'absolute',

                        top: '50%',
                        left: '50%',

                        transform: 'translateX(-50%) translateY(-50%)'
                    }} />

                </div>
            ];
        }
    } catch {
        return [];
    }
}

const ImageCarouselRon = ({
    handlers,
    images,
    currentImageIndex,
    isProcessing,
    bundleType,
    setLength,
    setShowCreateVideoButton,
    setCreateVideoTier,
    bundleData
}) => {
    // const [imageIndex, setImageIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    const elements = useMemo(() => getCarouselElements(
        images = images,
        bundleType = bundleType,
        isProcessing = isProcessing,
        bundleData = bundleData
    ), [isProcessing, images, bundleData]);

    const time_to_wait_seconds = bundleType == 1 ? 60 : bundleType == 3 ? 180 : 10000;

    useEffect(() => {
        setLength(elements.length);
    }, [elements]);

    useEffect(() => {
        let interval;

        if (isProcessing) {
            interval = setInterval(() => {
                const elapsedSeconds = getSecondsSince(bundleData.createdAt);
                const newProgress = 1 - (Math.max(
                    0,
                    Math.min(100000, time_to_wait_seconds - elapsedSeconds)
                ) / time_to_wait_seconds);

                setProgress(newProgress);
            }, 1000); // Update every second
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isProcessing, time_to_wait_seconds, bundleData]);

    return (
        <div style={{
            width: '100%',
            aspectRatio: 1,
            overflow: 'hidden',
            position: 'relative'
        }}
            {...handlers}
        >
            {
                isProcessing && (
                    <div>
                        Processing
                    </div>
                )
            }

            {
                elements.map((item, index) => (
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: index < currentImageIndex ? '-100%' : (index == currentImageIndex ? '0' : '100%'),
                        transition: '0.2s all'
                    }} key={index}>
                        {React.cloneElement(item, { key: index, bundledata: bundleData })}

                        <div style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            backdropFilter: isProcessing ? 'blur(8px)' : '',
                            WebkitBackdropFilter: isProcessing ? 'blur(8px)' : '',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {isProcessing && (
                                <>
                                    <LoadingThingie />

                                    <div style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',

                                        transform: 'translateX(-50%) translateY(-50%)'
                                    }}>
                                        {Math.round(progress * 100)}%
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                ))
            }

        </div>
    );
};


const IconsRowButton = ({ text, type, onClick, isLoading, disabled, currentImageIndex }) => {
    return (
        <div
            onClick={(!disabled) && onClick}
            style={{
                backgroundColor: type === 'report' ? 'rgb(230, 50, 50)' : 'rgb(100,100,100)',
                fontSize: '12px',
                padding: '5px 7px',
                borderRadius: '7px',
                cursor: disabled ? 'default' : 'pointer',
                opacity: disabled ? 0.6 : 1,
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
            }}
        >
            {text}
            {isLoading && (

                <LoadingThingie style={{
                    width: '12px',
                    height: '12px',
                    RxBorderWidth: '2px',

                }} />

            )}
        </div>
    )
}

const IconsRow = ({ bundleId, isProcessing, includeButton, setReloadBundles, images, currentImageIndex }) => {
    const navigate = useNavigate();
    const [isCreatingPsl, setIsCreatingPsl] = useState(false);
    const { credits } = useSelector(state => state.auth.user || { credits: 0 });
    const dispatch = useDispatch();

    const handleCreatePslVideo = async (currentImageIndex, images) => {
        if (currentImageIndex < 2 || isCreatingPsl || isProcessing) return;

        const UPLOAD_COST = 15; // Credits cost for upload
        const remainingCredits = credits - UPLOAD_COST;

        if (credits < UPLOAD_COST) {
            dispatch(openDialog({
                title: 'Insufficient Credits',
                message: `You need ${UPLOAD_COST} credits to upload an image. You currently have ${credits} credits.`,
                confirmText: 'Get Credits',
                cancelText: 'Cancel',
                onConfirm: () => {
                    navigate('/subscribe');
                }
            }));
            return;
        }


        dispatch(openDialog({
            title: 'Confirm Creation',
            message: `Creating an image bundle costs ${UPLOAD_COST} credits. You will have ${remainingCredits} credits remaining.`,
            confirmText: 'Upload',
            cancelText: 'Cancel',
            onConfirm: async () => {
                let image_id = null;

                if (currentImageIndex === 2) {
                    image_id = images.filter((item) => (item.proc_type === 'src2src_cropped_brows_cheeks'))[0].id;
                }
                else {
                    image_id = images.filter((item) => (item.proc_type === 'src2trg_cropped_brows_cheeks'))[0].id;
                }

                setIsCreatingPsl(true);
                try {
                    const result = await createVideoForSpecificMedia(image_id);
                    // You might want to handle the successful creation here
                    // For example, showing a success message or updating the UI
                } catch (error) {
                    console.error('Failed to create PSL God bundle video:', error);
                    // You might want to show an error message to the user
                } finally {
                    setIsCreatingPsl(false);
                    setTimeout(() => setReloadBundles(true), 500);
                }
            }
        }));
    };

    return (
        <div style={{
            // backgroundColor:'red',
            height: '40px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '16px',
        }}>
            <FaRegBookmark style={{
                color: 'white',
            }}
                size="24px"
            />
            <FiSend style={{
                color: 'white',
            }}
                size="24px"
            />

            <div style={{
                flex: '1 0 0%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '10px',
            }}>
                {
                    includeButton && (
                        <IconsRowButton
                            text={["Create Video", "Create Video", "Create Chad Video", "Create PSL Video"][currentImageIndex]}
                            onClick={() => {
                                handleCreatePslVideo(currentImageIndex, images);
                            }}
                            isLoading={isCreatingPsl}
                            disabled={isCreatingPsl || isProcessing || currentImageIndex < 2}
                            currentImageIndex={currentImageIndex}
                        />
                    )
                }

            </div>
        </div>
    )
}

const Description = ({ user, text, hashtags, bundleType }) => {
    return (
        <div style={{
            // backgroundColor:'red',
            // height: '30px',
            width: '100%',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            flexWrap: 'wrap',

            gap: '4px',

            fontSize: '14px',
            color: 'white'
        }}>
            <div>
                <span style={{ fontWeight: 'bold' }}>{user}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                {text}
            </div>
            {/* {
                hashtags.map((item,index)=>(
                    <div key={index}>
                        {item}
                    </div>
                ))
            } */}
        </div>
    )
}

function formatDateString(dateString) {

    const now = new Date();
    const inputDate = new Date(dateString);

    // Calculate the time difference in milliseconds
    const timeDifference = now - inputDate;

    // Helper to format ordinal suffix for the day
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // Special case for teens
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    // Case 1: "Just now" (within 60 seconds)
    if (timeDifference < 60 * 5000) {
        return "Just now";
    }


    // Case 2: "X hours ago" (within 24 hours)
    const minutesDifference = Math.floor(timeDifference / (60 * 1000));
    if (minutesDifference < 60) {
        return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
    }


    // Case 2: "X hours ago" (within 24 hours)
    const hoursDifference = Math.floor(timeDifference / (60 * 60 * 1000));
    if (hoursDifference < 24) {
        return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
    }

    // Format month and day
    const options = { month: 'long', day: 'numeric' };
    const formattedDate = inputDate.toLocaleDateString('en-US', options);

    // Case 3: "December 10th" (within the same year)
    if (inputDate.getFullYear() === now.getFullYear()) {
        const day = inputDate.getDate();
        const ordinalSuffix = getOrdinalSuffix(day);
        return `${formattedDate}${ordinalSuffix}`;
    }

    // Case 4: "January 13th, 2022" (different year)
    const fullDateOptions = { month: 'long', day: 'numeric', year: 'numeric' };
    return inputDate.toLocaleDateString('en-US', fullDateOptions);
}

const BundleCard = ({ bundle, setReloadBundles }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [bundleData, setBundleData] = useState(bundle);
    const [length, setLength] = useState(0);
    const dateString = useMemo(() => formatDateString(bundleData.createdAt), [bundleData.createdAt]);
    const [showCreateVideoButton, setShowCreateVideoButton] = useState(false);
    const [createVideoTier, setCreateVideoTier] = useState("");

    // Add polling logic using useEffect
    useEffect(() => {
        let intervalId;

        const pollBundleStatus = async () => {
            try {
                console.log('Polling bundle status for', bundleData);
                const updatedBundleRaw = await getBundleInfo(bundleData.id);
                const updatedBundle = transformBundle(updatedBundleRaw);
                setBundleData(updatedBundle);

                // If bundle is no longer processing, clear the interval
                if (!updatedBundle.is_processing) {
                    clearInterval(intervalId);
                }
            } catch (error) {
                console.error('Error polling bundle status:', error);
                // Clear interval on error to prevent continuous failed requests
                clearInterval(intervalId);
            }
        };

        // Only start polling if bundle is processing
        if (bundleData.is_processing) {
            // Initial check
            pollBundleStatus();
            // Set up interval for subsequent checks
            intervalId = setInterval(pollBundleStatus, 5000);
        }

        // Cleanup function to clear interval when component unmounts
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [bundleData.id, bundleData.is_processing]);

    // Update all bundle references to use bundleData instead of bundle
    const isProcessing = bundleData.is_processing;

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (isProcessing) return;

            if (currentImageIndex < length - 1) {
                setCurrentImageIndex(prev => prev + 1);
            }
        },
        onSwipedRight: () => {
            if (isProcessing) return;

            if (currentImageIndex > 0) {
                setCurrentImageIndex(prev => prev - 1);
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <Card>
            <Header>
                <UserInfo>
                    <Avatar src={bundleData.user.profileImage} alt={bundleData.user.name} />
                    <UserMeta>
                        <UserName>{bundleData.user.name}</UserName>
                        {/* <PostDate>{new Date(bundle.createdAt).toLocaleDateString()}</PostDate> */}
                    </UserMeta>
                </UserInfo>
                <MenuButton>
                    <FiMoreHorizontal size={20} />
                </MenuButton>
            </Header>

            <ImageCarouselRon
                handlers={handlers}
                images={bundleData.images}
                currentImageIndex={currentImageIndex}
                isProcessing={isProcessing}
                bundleType={bundleData.type}
                setLength={setLength}
                setShowCreateVideoButton={setShowCreateVideoButton}
                setCreateVideoTier={setCreateVideoTier}
                bundleData={bundleData}
            />

            {
                (length > 1) &&
                (<CarouselDots>
                    {[...Array(length)].map((_, index) => (
                        <Dot
                            key={index}
                            $active={index === currentImageIndex}
                            onClick={() => setCurrentImageIndex(index)}
                        />
                    ))}
                </CarouselDots>)
            }


            <div style={{
                width: '100%',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',

                padding: '5px 20px',
                boxSizing: 'border-box',

            }}>
                <IconsRow
                    bundleId={bundleData.id}
                    isProcessing={isProcessing}
                    includeButton={bundleData.type === 1 && !bundleData.error_processing && !isProcessing}
                    setReloadBundles={setReloadBundles}
                    showCreateVideoButton={showCreateVideoButton}
                    createVideoTier={createVideoTier}
                    currentImageIndex={currentImageIndex}
                    images={bundleData.images}
                />
                <Description 
                user={bundleData.user.name} 
                text={bundleData.error_processing ? "Error processing bundle 😭 We refunded your credits" : (bundleData.type == 1 ? "Born to mog." : "Imagine standing next to this guy😳") } />
                <div style={{
                    color: 'rgb(100,100,100)',
                    fontSize: '12px',
                    marginTop: '8px',
                }}>{dateString}</div>
            </div>
        </Card>
    );
};

export default BundleCard; 