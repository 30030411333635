import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import errorReducer from '../features/error/errorSlice';
import dialogReducer from '../features/dialog/dialogSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        error: errorReducer,
        dialog: dialogReducer,
    },
}); 