import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoChevronBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import SubscriptionPlanCard from '../components/common/PlanCard';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser, updateUser } from '../features/auth/authSlice';
import { plans } from '../constants/plans';
import { FaCoins, FaSignOutAlt } from 'react-icons/fa';
import axios from 'axios';
import { updateProfilePicture } from '../services/api';
import WebappContainer from '../components/common/WebappContainer';
import LoadingThingie from '../components/common/LoadingThingie';
import { HeadGuideOverlay } from './UploadNewImage.styles';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #1a1a1a;
    color: white;
`;

const TopBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #252525;
    padding-left: 16px;
    padding-right: 16px;
    padding: 8px;
    height: 60px;
`;

const BackButton = styled.button`
    background: none;
    border: none;
    color: white;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Title = styled.h1`
    margin: 0;
    margin-left: 8px;
    font-size: 20px;
    font-weight: 600;
`;

const ProfileSection = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px 20px;
    position: relative;
    box-sizing: border-box;
`;

const ProfileImage = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 60px;
    margin-bottom: 16px;
`;

const UserName = styled.h2`
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    ${'' /* margin-bottom: 8px; */}
`;

const UserEmail = styled.p`
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    margin-bottom: 24px;
`;

const Footer = styled.div`
    margin-top: auto;
    padding: 20px;
`;

const FooterLinks = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    margin-bottom: 24px;
    
    a {
        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
        font-size: 14px;
        
        &:hover {
            color: white;
        }
    }
`;

const CancelButton = styled.button`
    width: 100%;
    padding: 16px;
    background-color: #FF4444;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #FF6666;
    }
`;

const SubscriptionNote = styled.p`
    color: rgba(235, 235, 235, 0.6);
    font-size: 14px;
    text-align: center;
    margin-top: 12px;
    max-width: 400px;
    line-height: 1.4;
`;

const CreditsContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.1);
    padding: 8px 16px;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    gap: 6px;
`;

const CreditsCount = styled.span`
    color: #fff;
    font-weight: 600;
    font-size: 16px;
`;

const CreditIcon = styled(FaCoins)`
    color: #FFD700;
    font-size: 14px;
`;

const ProfileImageContainer = styled.div`
    ${'' /* background-color: red; */}
    position: relative;
    cursor: pointer;
    
    &:hover::after {
        content: 'Change Picture';
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
        text-align: center;
        background: rgba(0, 0, 0, 0.7);
        padding: 4px;
        border-bottom-left-radius: 60px;
        border-bottom-right-radius: 60px;
        font-size: 14px;
    }
`;

const ActionButtons = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
    max-width: 400px;
    margin-top: 16px;
`;

const ActionButton = styled.button`
    flex: 1;
    padding: 12px;
    border-radius: 8px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.2s;

    &:hover {
        background: rgba(255, 255, 255, 0.15);
    }
`;

const UserProfile = ({ setReloadUser, reloadUser }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        console.log('set reload user');
        setReloadUser(true);
    }, []);

    const userPlan = {
        ...plans.find(p => p.id === user?.subscription_type || 'basic'),
    };

    const handleImageClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = handleImageChange;
        input.click();
    };

    const handleImageChange = async (event) => {
        const file = event.target.files?.[0];
        if (!file) return;

        try {
            setIsUploading(true);
            const base64 = await convertToBase64(file);

            const response = await updateProfilePicture(base64);

            if (response.success) {
                dispatch(updateUser({ picture: response.picture }));
            }
        } catch (error) {
            console.error('Error updating profile picture:', error);
            // You might want to add some error handling UI here
        } finally {
            setIsUploading(false);
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        window.location.reload();
    };

    return (
        <WebappContainer>
            <TopBar>
                <BackButton onClick={() => navigate('/')}>
                    <IoChevronBack size={24} />
                </BackButton>
                <Title>Profile</Title>
            </TopBar>

            <ProfileSection style={{
                maxWidth: "400px",
                margin: "auto",
                marginTop: 0,
            }}>
                <CreditsContainer>
                    {
                        reloadUser ?
                            <LoadingThingie style={{
                                width: '10px',
                                height: '10px',
                                borderWidth: '2px',
                                // position:'absolute',
                                // transform: 'scale(0.2)'
                            }} /> : <CreditsCount>{user?.credits || 0}</CreditsCount>
                    }

                    <CreditIcon />
                </CreditsContainer>

                <div style={{
                    // widows:'100%',
                    // backgroundColor:'red',
                    display: 'flex',
                    flexDirection: 'row',

                    overflow: 'hidden',
                    padding: '12px',
                    boxSizing: 'border-box',

                    gap: '15px',
                }}>
                    <ProfileImageContainer onClick={handleImageClick}>
                        <ProfileImage
                            src={user?.picture || 'https://via.placeholder.com/120'}
                            alt="Profile"
                            style={{ opacity: isUploading ? 0.5 : 1 }}
                        />
                    </ProfileImageContainer>
                    <div style={{
                        // backgroundColor:'blue',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',

                        boxSizing: 'border-box',
                    }}>
                        <UserName>{user?.name || 'User'}</UserName>
                        <UserEmail>{user?.email || ''}</UserEmail>
                    </div>
                </div>

                <div style={{ width: '100%', maxWidth: '400px' }}>
                    <SubscriptionPlanCard
                        plan={userPlan}
                        showStatus={true}
                        isClickable={false}
                        isActive={!user?.subscription?.ended_at}
                    />
                    <SubscriptionNote>
                        If you've cancelled your subscription through Gumroad or Lemon Squeezy, your plan will remain active until the end of your current billing period.
                    </SubscriptionNote>
                    <ActionButtons>
                        <ActionButton onClick={() => navigate('/credit-history')}>
                            <FaCoins />
                            Credit History
                        </ActionButton>
                        <ActionButton onClick={handleLogout}>
                            <FaSignOutAlt />
                            Logout
                        </ActionButton>
                    </ActionButtons>
                </div>
            </ProfileSection>

            <Footer>
                <FooterLinks>
                    <a href="/tos.txt" target="_blank">Terms of Service</a>
                    <a href="/privacy.txt" target="_blank">Privacy Policy</a>
                    <a href="/refunds.txt" target="_blank">Refund Policy</a>
                </FooterLinks>
                {/* <CancelButton>Cancel Subscription</CancelButton> */}
            </Footer>
        </WebappContainer>
    );
};

export default UserProfile; 