import React from 'react';
import {
    StepContainer,
    LogoContainer,
    Title,
    SubTitle,
    PlansContainer,
} from './SubscriptionScreen.styles';
import SubscriptionPlanCard from '../components/common/PlanCard';
import { plans } from '../constants/plans';
import { color_dict } from '../utils/ColorDict';
import WebappContainer from '../components/common/WebappContainer';

const PricingScreen = () => {
    return (
        <WebappContainer style={{
            backgroundColor: color_dict.backgroundColor,
            minHeight: '100vh',
        }}>
            <StepContainer style={{
                background: `linear-gradient(180deg, ${color_dict.lighterBackgroundColor} 0%, ${color_dict.backgroundColor} 100%)`,
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                backdropFilter: 'blur(5px)',
                border: '1px solid rgba(255, 255, 255, 0.05)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative'
            }}>
                <div style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: color_dict.lighterBackgroundColor,
                    padding: '20px 0',
                    zIndex: 10
                }}>
                    <LogoContainer>
                        <img
                            src="/images/looxer-short.png"
                            alt="Logo"
                            style={{
                                filter: 'drop-shadow(0 0 10px rgba(206, 32, 101, 0.3))'
                            }}
                        />
                    </LogoContainer>
                </div>

                <div style={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: '0 20px',
                    marginBottom: '20px'
                }}>
                    <Title style={{
                        color: 'white',
                        fontSize: '32px',
                        fontWeight: '300',
                        textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                    }}>
                        Pricing Plans
                    </Title>

                    <SubTitle style={{
                        color: 'rgba(255,255,255,0.8)',
                        marginBottom: '30px',
                        maxWidth: "400px"
                    }}>
                        The amount of photos you can generate depends on your plan. Each generation bundle costs 5 credit, "you-as-a-model" video costs 15.
                    </SubTitle>
                    <PlansContainer style={{
                        gap: '20px',
                    }}>
                        {plans.map((plan) => (
                            <SubscriptionPlanCard
                                key={plan.id}
                                plan={plan}
                                selected={false}
                            />
                        ))}
                    </PlansContainer>
                </div>
            </StepContainer>
        </WebappContainer>
    );
};

export default PricingScreen; 