import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    StepContainer,
    LogoContainer,
    Title,
    SubTitle,
    PlansContainer,
} from './SubscriptionScreen.styles';
import { selectCurrentUser } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';
import SubscriptionPlanCard from '../components/common/PlanCard';
import { plans } from '../constants/plans';
import { getHasSubscription } from '../services/api';
import { color_dict } from '../utils/ColorDict';
import LooxerButton from '../components/common/Button';
import { IoRocketSharp } from "react-icons/io5";
import WebappContainer from '../components/common/WebappContainer';

const SubscriptionScreen = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isWaitingForActivation, setIsWaitingForActivation] = useState(false);

    const user = useSelector(selectCurrentUser) || {};
    const navigate = useNavigate();

    useEffect(() => {
        if (user.subscription_id) {
            window.location.href = '/'; // Required for the /me endpoint to reload + update user credits count in UI
        }
    }, [user]);

    useEffect(() => {
        let interval;
        if (isWaitingForActivation) {
            interval = setInterval(async () => {
                try {
                    const response = await getHasSubscription();
                    if (response.has_subscription) {
                        navigate('/');
                    }
                } catch (error) {
                    console.error('Error checking subscription:', error);
                }
            }, 5000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isWaitingForActivation, navigate]);

    const handleSubscribe = () => {
        if (!selectedPlan) return;
        const planData = plans.find(plan => plan.id === selectedPlan);
        setIsWaitingForActivation(true);
        window.open(`https://looxer.gumroad.com/l/${planData.gumroadId}`, '_blank');
    };

    const selectedPlanData = plans.find(plan => plan.id === selectedPlan);

    return (
        <WebappContainer style={{
            backgroundColor: color_dict.backgroundColor,
            minHeight: '100vh',
        }}>
            <StepContainer style={{
                background: `linear-gradient(180deg, ${color_dict.lighterBackgroundColor} 0%, ${color_dict.backgroundColor} 100%)`,
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                backdropFilter: 'blur(5px)',
                border: '1px solid rgba(255, 255, 255, 0.05)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative'
            }}>
                <div style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: color_dict.lighterBackgroundColor,
                    padding: '20px 0',
                    zIndex: 10
                }}>
                    <LogoContainer>
                        <img
                            src="/images/looxer-short.png"
                            alt="Logo"
                            style={{
                                filter: 'drop-shadow(0 0 10px rgba(206, 32, 101, 0.3))'
                            }}
                        />
                    </LogoContainer>
                </div>

                <div style={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: '0 20px',
                    marginBottom: '100px'
                }}>
                    <Title style={{
                        color: 'white',
                        fontSize: '32px',
                        fontWeight: '300',
                        textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                    }}>
                        Looking Good!
                    </Title>

                    <SubTitle style={{
                        color: 'rgba(255,255,255,0.8)',
                        marginBottom: '30px',
                    }}>
                        Select a plan to watch your full transformation potential.
                    </SubTitle>
                    <PlansContainer style={{
                        gap: '20px',
                    }}>
                        {plans.map((plan) => (
                            <SubscriptionPlanCard
                                key={plan.id}
                                plan={plan}
                                selected={selectedPlan === plan.id}
                                onClick={() => setSelectedPlan(plan.id)}
                            />
                        ))}
                    </PlansContainer>
                </div>

                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: '20px',
                    backgroundColor: color_dict.backgroundColor,
                    borderTop: `1px solid ${color_dict.lighterBackgroundColor}`,
                    zIndex: 10
                }}>
                    <LooxerButton
                        onClick={handleSubscribe}
                        text="Start My Transformation"
                        active={!!selectedPlan}
                        startIcon={<IoRocketSharp />}
                        color={color_dict.logo_pink}
                    />
                </div>

                {isWaitingForActivation && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(26, 26, 26, 0.95)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '20px',
                        zIndex: 1000,
                    }}>
                        <div style={{
                            width: '50px',
                            height: '50px',
                            border: `4px solid ${color_dict.pink_pale_background}`,
                            borderTop: `4px solid ${color_dict.logo_pink}`,
                            borderRadius: '50%',
                            animation: 'spin 1s linear infinite',
                        }} />
                        <div style={{
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: '600',
                        }}>
                            Waiting for subscription activation...
                        </div>
                        <div style={{
                            color: 'rgba(255,255,255,0.6)',
                            fontSize: '16px',
                        }}>
                            You can close or refresh this tab at any time
                        </div>
                    </div>
                )}
            </StepContainer>
            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}
            </style>
        </WebappContainer>
    );
};

export default SubscriptionScreen; 