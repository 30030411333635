import { store } from '../app/store';

export const transformBundle = (bundle) => {
    const currentUser = store.getState().auth.user;

    return {
        id: bundle._id,
        user: {
            name: currentUser?.name || 'Unknown User',
            profileImage: currentUser?.picture || 'https://via.placeholder.com/120',
        },
        createdAt: bundle.created_at,
        images: [
            // Include original media as first image
            {
                url: bundle.original_media,
                proc_type: "og",
                id: bundle.original_media_id,
            },
            // Add bundle media if any exists
            ...(bundle.bundle_media || []).map(mediaPath => ({
                url: mediaPath.hasOwnProperty('url') ? mediaPath.url : mediaPath,
                proc_type: mediaPath.proc_type,
                id: mediaPath.id,
            }))
        ],
        is_processing: bundle.bundle_media.length === 0 || bundle.is_processing,
        error_processing: bundle.error_processing,
        type: bundle.type,
    };
}; 
