export const plans = [
    {
        id: 'basic',
        name: 'LTN Plan',
        subtitle: 'See your full looksmaxxing potential',
        price: 9.99,
        period: '/ month',
        features: [
            '40 AI-generation credits / month',
            '"You as a Model" photoshoot pack',
        ],
        gumroadId: 'basic_ltn_monthly'
    },
    {
        id: 'premium',
        name: 'Chad Plan',
        subtitle: 'Why not get your brother a chiseled jawline?',
        price: 39.99,
        period: '/ month',
        features: [
            '200 AI-generation credits / month',
        ],
        gumroadId: 'premium_chad_monthly'
    },
    {
        id: 'super',
        name: 'PSL God',
        subtitle: 'Heck, why not give grandma a facelift as well?',
        price: 69.99,
        period: '/ month',
        features: [
            '400 AI-generation credits / month',
        ],
        gumroadId: 'super_psl_god_monthly'
    }
]; 