import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isError: false,
    errorType: null, // 'api' | 'connectivity' | null
    message: null,
    endpoint: null
};

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setApiError: (state, action) => {
            state.isError = true;
            state.errorType = 'api';
            state.message = action.payload.message;
            state.endpoint = action.payload.endpoint;
        },
        setConnectivityError: (state) => {
            state.isError = true;
            state.errorType = 'connectivity';
            state.message = 'Network error: Our servers are experiencing issues. Please try again later.';
        },
        clearError: (state) => {
            state.isError = false;
            state.errorType = null;
            state.message = null;
            state.endpoint = null;
        }
    }
});

export const { setApiError, setConnectivityError, clearError } = errorSlice.actions;
export const selectError = (state) => state.error;
export default errorSlice.reducer; 