import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    title: '',
    message: '',
    confirmText: '',
    cancelText: '',
    onConfirm: null,
    onCancel: null,
};

const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        openDialog: (state, action) => {
            return {
                ...state,
                isOpen: true,
                ...action.payload,
            };
        },
        closeDialog: (state) => {
            return initialState;
        },
    },
});

export const { openDialog, closeDialog } = dialogSlice.actions;
export const selectDialog = (state) => state.dialog;
export default dialogSlice.reducer; 