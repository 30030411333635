import React from 'react';
import { color_dict } from '../utils/ColorDict';

function InstagramRedirectScreen() {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundColor: color_dict.backgroundColor,
            padding: '20px',
            textAlign: 'center',
            color: "white",
            position: 'relative'
        }}>
            {/* Up-right arrow */}
            <div style={{
                position: 'absolute',
                top: '0px',
                right: '20px',
                fontSize: '70px',
                transform: 'rotate(45deg)'
            }}>
                ↑
            </div>

            <div style={{ flex: 1 }}>&nbsp;</div>

            {/* Looxer Logo */}
            <img
                src="/images/looxer.png"
                alt="Looxer.AI"
                style={{
                    width: '120px',
                    marginBottom: '30px'
                }}
            />

            {/* Hi! text */}
            <h1 style={{
                color: 'white',
                marginBottom: '20px',
                fontSize: '32px',
                fontWeight: 'bold'
            }}>
                Hi!
            </h1>

            {/* Instructions text */}
            <p style={{
                color: color_dict.text_gray,
                marginBottom: '30px',
                fontSize: '16px',
                lineHeight: '1.5',
                maxWidth: '280px',
                verticalAlign: 'middle',
            }}>
                Looxer.AI works best in your browser. Use the&nbsp;&nbsp;<span style={{
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    letterSpacing: '1px',
                    display: 'inline-block',
                    transform: 'rotate(90deg)',
                    margin: '0 4px',
                    fontSize: '24px'
                }}>⋮</span>&nbsp;&nbsp;menu to open in Chrome or Safari
            </p>

            <div style={{ flex: 2 }}>&nbsp;</div>
        </div>
    );
}

export default InstagramRedirectScreen; 