export const color_dict = {
    backgroundColor: '#1a1a1a',
    lighterBackgroundColor: '#252525',
    darkerBackgroundCOlor: '#0a0a0a',

    logo_pink: 'rgb(206, 32, 101)',
    pink_pale_background: 'rgba(206, 32, 101, 0.1)',
    logo_pink_exciting: 'rgb(255, 90, 150)',
    logo_purple: 'rgb(131, 58, 131)',
    logo_purple_highlight: 'rgb(191, 64, 191)'
}

