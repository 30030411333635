import React, { useState, useRef, useEffect } from 'react';
import {
    Container,
    ContentContainer,
    Title,
    Description,
    CameraContainer,
    CameraWindow,
    ImagePreview,
    ButtonContainer,
    CloseButton,
    ErrorMessage,
    ScrollContent,
    HeadGuideOverlay
} from './UploadNewImage.styles';
import { IoClose, IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { FaCamera, FaUpload } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { uploadBundlePhoto } from '../services/api';
import LooxerButton from '../components/common/Button';
import { color_dict } from '../utils/ColorDict';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../features/dialog/dialogSlice';
import WebappContainer from '../components/common/WebappContainer';


const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
    });

const getImageOrientation = async (imageDataUrl) => {
    const img = await createImage(imageDataUrl);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set proper canvas dimensions before transform & export
    canvas.width = img.width;
    canvas.height = img.height;

    // Draw image with correct orientation
    ctx.drawImage(img, 0, 0);

    // If the image is from camera (usually means it's mirrored)
    if (imageDataUrl.includes('camera')) {
        ctx.scale(-1, 1);
        ctx.translate(-canvas.width, 0);
        ctx.drawImage(img, 0, 0);
    }

    return canvas.toDataURL('image/jpeg', 0.8);
};

export const normalizeImageOrientation = async (imageSource) => {
    if (!imageSource) return null;

    try {
        const normalizedImage = await getImageOrientation(imageSource.data);
        return {
            ...imageSource,
            data: normalizedImage
        };
    } catch (error) {
        console.error('Error normalizing image orientation:', error);
        return imageSource;
    }
};

const UploadNewImage = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [imageSource, setImageSource] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);
    const [isCameraReady, setIsCameraReady] = useState(false);
    const videoRef = useRef(null);
    const streamRef = useRef(null);
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const { credits } = useSelector(state => state.auth.user || { credits: 0 });

    const startCamera = async () => {
        setTimeout(async () => {
            try {
                videoRef.current.setAttribute('autoplay', '');
                videoRef.current.setAttribute('muted', '');
                videoRef.current.setAttribute('playsInline', '');

                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                videoRef.current.srcObject = stream;
                streamRef.current = stream;
            } catch (err) {
                console.error("Error accessing camera:", err);
            }
        }, 200);
    };

    const stopCamera = () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
        }
    };

    const capturePhoto = () => {
        const video = videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0);
        const image = canvas.toDataURL('image/jpeg');
        setImageSource({
            data: image,
            fromCamera: true
        });
        stopCamera();
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSource({
                    data: reader.result,
                    fromCamera: false
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleReadyClick = () => {
        setIsCameraReady(true);
        startCamera();
    };

    useEffect(() => {
        return () => stopCamera();
    }, []);

    const handleSubmit = async () => {
        if (!imageSource) return;

        const UPLOAD_COST = 5; // Credits cost for upload
        const remainingCredits = credits - UPLOAD_COST;

        if (credits < UPLOAD_COST) {
            dispatch(openDialog({
                title: 'Insufficient Credits',
                message: `You need ${UPLOAD_COST} credits to upload an image. You currently have ${credits} credits.`,
                confirmText: 'Get Credits',
                cancelText: 'Cancel',
                onConfirm: () => {
                    navigate('/subscribe');
                }
            }));
            return;
        }

        dispatch(openDialog({
            title: 'Confirm Creation',
            message: `Creating an image bundle costs ${UPLOAD_COST} credits. You will have ${remainingCredits} credits remaining.`,
            confirmText: 'Upload',
            cancelText: 'Cancel',
            onConfirm: async () => {
                setIsUploading(true);
                setError(null);

                try {
                    const normalizedImage = await normalizeImageOrientation(imageSource);
                    const response = await uploadBundlePhoto(normalizedImage.data);
                    if (response.success) {
                        navigate('/');
                    }
                } catch (err) {
                    setError('Failed to upload image. Please try again.');
                    console.error('Upload error:', err);
                } finally {
                    setIsUploading(false);
                }
            }
        }));
    };

    return (
        <WebappContainer>
            <CloseButton onClick={() => navigate(-1)}>
                <IoClose size={24} />
            </CloseButton>
            <ContentContainer>
                <Title>Add New Photo</Title>
                <Description>
                    Take or upload a new photo to track your progress
                </Description>

                <CameraContainer style={{
                    border: (!isCameraReady && !imageSource) ? `2px solid ${color_dict.logo_purple}` : 'none',
                    maxWidth: "400px",
                    flex: '1 0 0%',
                    margin: "auto",
                    position:'relative',
                }}>
                    {imageSource ? (
                        <ImagePreview
                            src={imageSource.data}
                            alt="Captured"
                            style={{
                                position:'absolute',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                transform: `translateX(-50%) translateY(-50%) ${imageSource.fromCamera ? 'scaleX(-1)' : ''}`,
                            
                                left:'50%',
                                top:'50%',
                            }}
                        />
                    ) : isCameraReady ? (
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <CameraWindow
                                ref={videoRef}
                                autoPlay
                                playsInline
                            />
                            <HeadGuideOverlay />
                        </div>
                    ) : (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            gap: '20px',
                            padding: '20px'
                        }}>
                            <Description style={{
                                textAlign: 'center',
                                color: "white"
                            }}>
                                We'll need access to your camera.
                            </Description>
                            <LooxerButton
                                onClick={handleReadyClick}
                                text="I'm ready"
                                active={true}
                                color={color_dict.logo_purple}
                            />
                        </div>
                    )}
                </CameraContainer>

                <ButtonContainer>
                    {!imageSource ? (
                        isCameraReady ? (
                            <>
                                <LooxerButton
                                    onClick={capturePhoto}
                                    text="Take Photo"
                                    active={true}
                                    startIcon={<FaCamera />}
                                />
                                <LooxerButton
                                    onClick={handleUploadClick}
                                    text="Upload Photo"
                                    active={true}
                                    secondary
                                    startIcon={<FaUpload />}
                                />
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileUpload}
                                    style={{ display: 'none' }}
                                />
                            </>
                        ) : null
                    ) : (
                        <>
                            <LooxerButton
                                onClick={() => {
                                    setImageSource(null);
                                    startCamera();
                                }}
                                text="Retake"
                                active={!isUploading}
                                secondary
                                startIcon={<IoChevronBack />}
                            />
                            <LooxerButton
                                onClick={handleSubmit}
                                text={isUploading ? 'Uploading...' : 'Continue'}
                                active={!isUploading}
                                endIcon={<IoChevronForward />}
                            />
                        </>
                    )}
                </ButtonContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}
            </ContentContainer>
        </WebappContainer>
    );
};

export default UploadNewImage; 