import { useState } from "react";
import { color_dict } from "../../utils/ColorDict";

const LooxerButton = ({ style, onClick, text, active, color, secondary, startIcon, endIcon }) => {

    const backgroundColor = secondary ? '#252525' : color || color_dict.logo_pink;
    const border = secondary ? '1px solid rgba(255,255,255,0.2)' : 'none';

    return (
        <div style={{
            opacity: active ? 1 : 0.5,
            backgroundColor: backgroundColor,
            border: border,

            cursor: active ? 'pointer' : 'default',

            padding: '12px 25px',
            borderRadius: '30px',

            fontSize: '15px',
            fontWeight: '300',
            transition: '0.2s all',
            ...style
        }}
            onClick={(e) => {
                if (active)
                    onClick(e)
            }}
        >
            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                justifyContent: 'center'
            }}>
                {startIcon}
                {text}
                {endIcon}
            </div>
        </div>
    )
}

export default LooxerButton;