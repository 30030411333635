import styled from "styled-components";
import { color_dict } from "../utils/ColorDict";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${color_dict.backgroundColor};
  color: white;
`;

export const StepContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
  min-height: 0;

  h1 {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
`;

export const TopBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #252525;
    padding-right: 16px;
    padding-left: 16px;
    height: 60px;
`;

export const LogoContainer = styled.div`
    height: 80%;
    aspect-ratio: 2;
    position: relative;
    overflow: hidden;

    img {
        height: 110%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
`;

export const MotivationalText = styled.div`
  text-align: left;
  font-size: 26px;
  margin: 24px 0 8px 0;
  width: 100%;
  box-sizing: border-box;
`;

export const CameraContainer = styled.div`
  width: 100%;
  max-width: 400px;
  aspect-ratio: 3/4;
  background-color: #252525;
  border-radius: 12px;
  overflow: hidden;
  margin: 20px 0;
  position: relative;
  min-height: 0;
`;

export const CameraWindow = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);
`;

export const CaptureButton = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  background-color: #FFD700;
  color: #1a1a1a;
  border: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.05);
    background-color: #FFEB3B;
  }
`;

export const UploadButton = styled(CaptureButton)`
  background-color: #252525;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);

  &:hover {
    background-color: #303030;
  }
`;

export const NextButton = styled(CaptureButton)`
  &:hover {
    transform: translateX(2px);
  }
`;

export const SubTitle = styled.p`
  color: rgba(160, 160, 160, 0.8);
  text-align: left;
  font-size: 16px;
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
`; 